import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useNavigationGuard(blocking = true) {
  const location = useLocation();
  const navigate = useNavigate();
  const lastLocationRef = useRef(location); // store last confirmed location
  const [isReverting, setIsReverting] = useState(false);

  useEffect(() => {
    if (location.pathname === lastLocationRef.current.pathname || isReverting) {
      return;
    }
    // eslint-disable-next-line
    const orderPopSaveButton = document.querySelector("#orderPopSaveButton");

    if (blocking) {
      const confirmed = window.confirm(
        "Are you sure you want to leave this page?, Your changes won't be saved"
      );
      if (!confirmed) {
        setIsReverting(true);
        navigate(lastLocationRef.current.pathname, { replace: true });
        return;
      }
    }

    lastLocationRef.current = location;
  }, [location, blocking, isReverting, navigate]);

  useEffect(() => {
    if (isReverting && location.pathname === lastLocationRef.current.pathname) {
      setIsReverting(false);
    }
  }, [location, isReverting]);
}
