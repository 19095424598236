import React, { lazy, Suspense, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { useLocation } from "react-router-dom";
import { useNavigationGuard } from "utils/hooks/useNavigationGuard";
import { useOrderSave } from "utils/hooks/OrderSaveContext";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const [shouldBlock, setShouldBlock] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  let location = useLocation();

  // create a ref
  const { isOrderSaved } = useOrderSave();

  useEffect(() => {
    if (location.pathname.includes("edit-orders") && !isOrderSaved) {
      setShouldBlock(true);
    } else {
      setShouldBlock(false);
    }
  }, [location, isOrderSaved]);

  useNavigationGuard(shouldBlock);

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
