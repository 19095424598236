import React, { createContext, useContext, useState, ReactNode } from "react";

interface OrderSaveContextProps {
  isOrderSaved: boolean;
  setIsOrderSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderSaveContext = createContext<OrderSaveContextProps | undefined>(
  undefined
);

export const OrderSaveProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOrderSaved, setIsOrderSaved] = useState<boolean>(true);

  return (
    <OrderSaveContext.Provider value={{ isOrderSaved, setIsOrderSaved }}>
      {children}
    </OrderSaveContext.Provider>
  );
};

export const useOrderSave = (): OrderSaveContextProps => {
  const context = useContext(OrderSaveContext);
  if (!context) {
    throw new Error("useOrderSave must be used within an OrderSaveProvider");
  }
  return context;
};
